<template>
  <div class="login-container">
    <div class="position1" />
    <div class="position2" />
    <div class="position3" />
    <div class="rigtBg">
      <div class="submitContent">
        <img src="@/assets/images/binxi3.png">
        <el-form ref="ref_loginForm" :model="loginForm" :rules="loginRules">
          <el-form-item prop="username">
            <input type="password" style="display:none">
            <el-input ref="ref_username" v-model.trim="loginForm.username" placeholder="请输入用户名 | Enter your account">
              <SvgIcon slot="prefix" icon-class="tel" size="14px" />
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <input type="password" style="display:none">
            <el-input ref="ref_password" v-model.trim="loginForm.password" type="password"
              placeholder="请输入密码 | Enter your password" @keyup.enter.native="login">
              <SvgIcon slot="prefix" icon-class="psw" size="14px" />
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%;" @click="login">登 录 | Log in</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login, verifyToken } from '@api/login/api_login'
import { findUser } from '@api/bx/api_contentManagement';
import axios from 'axios'
export default {
  data() {
    return {
      checked: false,
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { pattern: /^(\w){6,20}$/, message: '字母,数字,下划线组成6-20位字符', trigger: 'blur' }
        ]
      },
      version:'V1.2'
    }
  },
  watch: {

  },
  created() {
   
  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.ref_username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.ref_password.focus()
    }
  },
  destroyed() {

  },
  methods: {
     //处理发包缓存问题
     contractCache() {
      axios
        .get(`/config.json`, {
          params: {
            _t: new Date().getTime(),
          },
        })
        .then((res) => {
          console.log(777777)
          // console.log(res)
          const VUE_APP_VERSION = res.data.version;
          const vers = window.localStorage.getItem("Version");

          // 如果缓存没有版本号，则是第一次打开，记录当前版本号并返回
          if (!vers) {
            window.localStorage.setItem("Version", VUE_APP_VERSION);
            return;
          }

          // 检查当前版本是否和缓存的版本不一致
          if (VUE_APP_VERSION != vers) {
            setTimeout(() => {
              // alert(this.$t(`omission.newVersionDetectedPleaseRefresh`));

              // 更新版本号并刷新页面
              window.localStorage.setItem("Version", VUE_APP_VERSION);
              console.log(VUE_APP_VERSION)
              location.reload();
            }, 2000);
          }
        });
    },
    login() {
      this.$refs.ref_loginForm.validate(valid => {
        if (!valid) { return false }
        login(this.loginForm).then(res => {
          localStorage.setItem('token', res.data.token_type + ' ' + res.data.access_token)
          let token = localStorage.getItem("token");
          console.log(token, 99999)
          this.$store.commit('SET_TOKEN', res.data.token_type + ' ' + res.data.access_token)
         
          // 验证token 保证同一账号 只能在同一台电脑登录
          verifyToken().then(res2 => {
            // this.$router.push('/')
            // this.$router.push('/bxDcUserMenu/bxDcUserConf')
            this.$router.push('/bxDcUserMenu/bxDcUserConf')
            // findUser({')
            // this.contractCache()
         
            
            //   loginAccount: this.loginForm.username,
            //   page: 1,
            //   size: 10
            // }).then(info => {
            //   if (info.records[0].roleId == 3) {
            //     // roleId = 3   总部账号   roleId = 4 代理商账号  roleId = 5  代理商子账号
            //     this.$router.push('/bxMember/member')
            //   } else {
            //     this.$router.push('/bxMchUser/userList')
            //   }
            //   localStorage.setItem('setPassword', JSON.stringify({
            //     password:info.records[0].password,
            //     contactPhone:info.records[0].contactPhone,
            //     loginAccount:info.records[0].loginAccount,
            //     id:info.records[0].id
            //   }));

            // })
       

          })
        }).catch(error => {
          if (error.response) {
            console.log(error.response)
            this.$message.closeAll()
            if (error.response.status === 400) {
              this.$message.error(error.response.data.message)
            } else if (error.response.status === 401) {
              this.$message.error('用户名或密码错误')
            }
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
.login-container {
  background: url('./../assets/images/img1.jpg') center no-repeat;
  background-color: aquamarine;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  // .position1{
  //   position: fixed;
  //   left:0;
  //   top:18%;
  //   width: 17%;
  //   height: 20%;
  //   // background: url('./../assets/images/img3.png') no-repeat;
  //   background-size: 100% 100%;
  //   z-index: 1;
  // }
  // .position2{
  //   position: fixed;
  //   left:6%;
  //   top:17%;
  //   width: 46%;
  //   height: 73%;
  //   // background: url('./../assets/images/img5.png') no-repeat;
  //   background-size: 100% 100%;
  //   z-index: 1;
  // }
  // .position3{
  //   position: fixed;
  //   right:28%;
  //   top:0;
  //   width: 17%;
  //   height: 35%;
  //   // background: url('./../assets/images/img4.png') no-repeat;
  //   background-size: 100% 100%;
  //   z-index: 2;
  // }
  .rigtBg {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    // background: url('./../assets/images/img2.png') no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    width: 58%;
  }

  .submitContent {
    width: 37%;
    position: absolute;
    right: 13%;
    top: 30%;
    text-align: center;

    img {
      width: 130px;
      height: 130px;
      margin-bottom: 11%;
    }

    .el-form-item {
      margin-bottom: 8%;
    }

    .el-input--prefix .el-input__inner {
      box-shadow: 0px 4px 10px;
      background-color: white;
    }

    .el-button {
      margin-top: 20px;
      background-color: #ced4df;
    }

    .el-checkbox__label {
      color: #ced4df;
    }

    .el-input--prefix .el-input__inner {
      padding-left: 40px;
    }

    .el-input__prefix {
      left: 17px;
    }

    .el-button--primary {
      border-color: #ced4df;
    }

    .el-input__inner {

      // border-color: transparent;
      &:hover {
        border-color: #ced4df;
      }
    }
  }
}
</style>

